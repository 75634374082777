<template>
  <div>
    <div class="card">
      <Toast position="top-center" group="info" />

      <Button
        label="Создать запрос на эмиссию"
        class="p-button-primary"
        @click="showCreateDialog"
      />

      <hr />
      <div v-if="issuers.length" class="my-issuers">
        <h5>Мои запросы на выпуск ЦФА</h5>
        <DataTable
          dataKey="id"
          paginator
          lazy
          :value="issuers"
          :rows="rows"
          :totalRecords="totalItems"
          v-model:issuers="issuers"
          responsiveLayout="scroll"
          :sortable="true"
          @page="setPage"
        >
          <Column
            header="Актив"
            field="tokenNameEng"
            style="word-break: break-all"
          />
          <Column header="Абр" field="tokenMnemonicEng" />
          <Column
            header="Кол"
            field="tokenCount"
            style="word-break: break-all"
          />
          <Column header="Статус">
            <template #body="slot">
              {{ slot.data.confirmedAt == null ? 'Ожидание' : 'Исполнен' }}
            </template>
          </Column>
          <Column
            class="bg-white border-none flex flex-nowrap justify-content-end"
          >
            <template #body="slot">
              <Button
                v-if="slot.data.isDraft"
                icon="pi pi-pencil"
                class="p-button-rounded p-button-warning m-1"
                @click="edit(slot.data)"
              />
              <Button
                icon="pi pi-trash"
                class="p-button-rounded p-button-danger m-1"
                @click="del(slot.data)"
              />
            </template>
          </Column>
        </DataTable>
      </div>
      <div v-else class="no-company">
        <p>У вас еще нет запросов</p>
      </div>
    </div>

    <!-- Dialogs -->
    <Dialog
      v-model:visible="createEditDialog"
      :style="{ width: '700px' }"
      :header="dialogHeader"
      class="p-fluid"
    >
      <div class="flex">
        <div class="flex-column col-6">
          <label for="ActiveName" class="LabelText"
            ><b>Наименование актива(*)</b></label
          >
          <InputText
            id="ActiveName"
            placeholder="Наименование актива"
            v-model="tokenName"
          />
          <label for="Description" class="LabelText">Описание</label>
          <InputText
            id="Description"
            placeholder="Описание"
            v-model="description"
          />
          <label for="CompanyName" class="LabelText"
            ><b>Выберите компанию(*)</b></label
          >
          <Dropdown
            id="CompanyName"
            v-model="selectedCompany"
            :options="companies"
            optionLabel="nameRus"
            :filter="true"
            placeholder="Выберите компанию"
            :showClear="true"
          >
            <template #option="slotProps">
              <div class="company-item">
                <div>{{ slotProps.option.nameRus }}</div>
              </div>
            </template>
          </Dropdown>
          <label for="LogicName" class="LabelText"
            ><b>Логический тип(*)</b></label
          >
          <Dropdown
            id="LogicName"
            v-model="logicType"
            :options="logicTypeList"
            optionLabel="name"
            placeholder="Логический тип"
          />
          <label for="divid" class="LabelText">Дивиденды</label>
          <InputText id="divid" v-model="dividends" type="number" />
        </div>

        <div class="flex-column col-6">
          <label for="ShortDescr" class="LabelText"
            ><b>Сокращённое обозначение(*)</b></label
          >
          <InputText
            id="ShortDescr"
            placeholder="Сокращённое обозначение"
            v-model="tokenMnemonic"
          />
          <label for="PaperNumb" class="LabelText"
            ><b>Количество выпускаемых ценных бумаг(*)</b></label
          >
          <InputText id="PaperNumb" v-model="tokenCount" type="number" />
          <label for="EthName" class="LabelText"
            ><b>Адрес для перевода средств эмиссии(*)</b></label
          >
          <Dropdown
            id="EthName"
            v-model="selectedEth"
            :options="eth"
            optionLabel="value"
            :filter="true"
            placeholder="Адресс эмиссии"
            :showClear="true"
          >
            <template #option="slotProps">
              <div class="company-item">
                <div>{{ slotProps.option.value }}</div>
              </div>
            </template>
          </Dropdown>
          <label for="Calendar" class="LabelText">Дата погашения</label>
          <Calendar
            id="Calendar"
            v-model="stopDate"
            placeholder="Дата погашения"
            date-format="dd.mm.yy"
            :disabled="infinityDate"
          />
          <label for="disc" class="LabelText">Скидка</label>
          <InputText id="disc" v-model="discount" type="number" />
        </div>
      </div>

      <div v-if="isManager">
        <label for="decimals" class="LabelText">Знаков после запятой</label>
      </div>

      <div class="p-inputgroup" v-if="isManager">
        <InputText
          id="decimals"
          v-model="decimals"
          placeholder="Знаков после запятой"
          type="number"
        />
      </div>

      <div class="field-checkbox my-3">
        <Checkbox id="isVotingRight" v-model="isVotingRight" :binary="true" />
        <label for="isVotingRight">Право голоса</label>
      </div>
      <div class="field-checkbox my-3">
        <Checkbox id="infinityDate" v-model="infinityDate" :binary="true" />
        <label for="infinityDate">Бессрочная дата погашения</label>
      </div>
      <small
        class="block m-1 p-error"
        v-if="!$filters.correctMnemonic(tokenMnemonic)"
        >Введите от 2 до 5 латинских символов</small
      >
      <small
        class="block m-1 p-error"
        v-if="!$filters.correctNumber(tokenCount)"
        >Введите число</small
      >
      <small class="block m-1 p-error" v-if="!allField"
        >Пожалуйста заполните все поля правильно</small
      >

      <Button
        :disabled="!allField"
        :label="buttonText"
        class="p-button-primary"
        @click="checkEditOrCreate"
      />
    </Dialog>

    <Dialog
      v-model:visible="deleteDialog"
      :style="{ width: '450px' }"
      header="Удалить актив?"
      :modal="true"
    >
      <div class="confirmation-content">
        <p class="text-center p-error" v-if="itemEdit">
          Вы уверены, что хотите удалить?
        </p>
        <p class="text-center">
          <b>{{ editValue.tokenName }}</b>
        </p>
      </div>
      <template #footer>
        <Button
          label="Нет"
          icon="pi pi-times"
          class="p-button-text"
          @click="deleteDialog = false"
        />
        <Button
          label="Да"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteConfirm"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'Emissions',
  head() {
    return {
      title: 'Emissions',
    }
  },
  data() {
    return {
      userId: this.$auth.$vm.state.data.id,
      companies: {},
      eth: {},
      issuers: {},
      selectedCompany: null,
      selectedEth: null,
      tokenName: '',
      tokenMnemonic: '',
      description: '',
      tokenCount: '',
      editValue: null,
      createEditDialog: false,
      deleteDialog: false,
      logicType: '',
      logicTypeList: [
        { name: 'ЦФА', Type: 'asset' },
        { name: 'Токен безопасности', Type: 'security' },
        { name: 'Служебный токен', Type: 'utility' },
        { name: 'Фиат', Type: 'fiat' },
        { name: 'DAO', Type: 'dao' },
        { name: 'Золото', Type: 'gold' },
      ],
      stopDate: '',
      dividends: '',
      discount: '',
      decimals: 0,
      isVotingRight: false,
      infinityDate: false,
      page: 1,
      itemsPerPage: 30,
      rows: 30,
      totalItems: 10,
    }
  },
  async mounted() {
    this.getCompanies()
    this.getEth()
    this.getRequestToIssues()
    if (this.$auth.user().type === 'user') {
      this.logicTypeList.splice(3, 3)
    }
  },
  methods: {
    setPage(event) {
      this.page = event.page + 1
      this.getRequestToIssues()
    },
    async getCompanies() {
      const resp = await this.axios.get('api/companies')
      this.companies = resp.data
    },
    async getEth() {
      const resp = await this.axios.get('api/eth_addresses')
      this.eth = resp.data
    },
    async getRequestToIssues() {
      const headers = {
        accept: 'application/ld+json',
        'Content-Type': 'application/ld+json',
      }
      const params = {
        page: this.page,
        itemsPerPage: this.itemsPerPage,
        pagination: true,
      }
      try {
        const resp = await this.axios.get('api/request_to_issues', {
          headers,
          params,
        })
        this.issuers = resp.data['hydra:member']
        this.totalItems = resp.data['hydra:totalItems']
      } catch (error) {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Error in request_to_issues api call',
          group: 'info',
          life: 3000,
        })
      }
    },
    async createRequestToIssuer() {
      this.createEditDialog = false
      const headers = {
        accept: 'application/json',
        'Content-Type': 'application/json',
      }
      const params = {
        description: this.description,
        tokenName: this.tokenName,
        tokenMnemonic: this.tokenMnemonic,
        tokenCount: this.tokenCount,
        user: `/api/users/${this.userId}`,
        company: `/api/companies/${this.selectedCompany.id}`,
        createdAt: moment().format('DD.MM.YYYY'),
        isDraft: true,
        ethAddress: `/api/eth_addresses/${this.selectedEth.id}`,
        isCompleted: false,
        logicType: this.logicType.Type,
        dividends: this.dividends,
        discount: this.discount,
        decimals: Number(this.decimals),
        isVotingRight: this.isVotingRight,
      }
      if (this.stopDate.length !== 0) params.stopDate = this.stopDate
      try {
        await this.axios.post('api/request_to_issues', params, headers)
        this.$toast.add({
          severity: 'success',
          summary: 'Successful',
          detail: 'Request created',
          group: 'info',
          life: 3000,
        })
        this.getRequestToIssues()
        this.clear()
      } catch (e) {
        console.log('err', e)
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Request not created',
          group: 'info',
          life: 3000,
        })
      }
    },

    edit(value) {
      this.editValue = value
      this.tokenName = value.tokenName
      this.tokenMnemonic = value.tokenMnemonic
      this.description = value.description
      this.tokenCount = value.tokenCount
      this.dividends = value.dividends
      this.discount = value.discount
      this.decimals = value.decimals
      this.isVotingRight = value.isVotingRight
      this.stopDate = moment(value.stopDate).format('DD.MM.YYYY')
      this.logicType = this.logicTypeList.find((el) => {
        return el.Type === value.logicType
      })
      this.selectedCompany = this.companies.find((el) => {
        return el.id === Number(value.company.split('/companies/')[1])
      })
      this.selectedEth = this.eth.find((el) => {
        return el.id === Number(value.ethAddress.split('/eth_addresses/')[1])
      })
      this.createEditDialog = true
    },

    async editConfirm() {
      this.createEditDialog = false
      const headers = {
        accept: 'application/json',
        'Content-Type': 'application/json',
      }
      const params = {
        description: this.description,
        tokenName: this.tokenName,
        tokenMnemonic: this.tokenMnemonic,
        tokenCount: this.tokenCount,
        company: `/api/companies/${this.selectedCompany.id}`,
        ethAddress: `/api/eth_addresses/${this.selectedEth.id}`,
        logicType: this.logicType.Type,
        dividends: this.dividends,
        discount: this.discount,
        decimals: Number(this.decimals),
        isVotingRight: this.isVotingRight,
      }
      if (this.stopDate.length !== 0) params.stopDate = this.stopDate
      try {
        await this.axios.put(
          `api/request_to_issues/${this.editValue.id}`,
          params,
          headers
        )
        this.$toast.add({
          severity: 'success',
          summary: 'Successful',
          detail: 'Request success',
          group: 'info',
          life: 3000,
        })
        this.getRequestToIssues()
      } catch (e) {
        console.log('err', e)
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Request error',
          group: 'info',
          life: 3000,
        })
      }
    },

    del(value) {
      this.editValue = value
      this.deleteDialog = true
    },

    async deleteConfirm() {
      const headers = {
        accept: '*/*',
      }
      try {
        await this.axios.delete(
          `api/request_to_issues/${this.editValue.id}`,
          headers
        )
        this.$toast.add({
          severity: 'success',
          summary: 'Successful',
          detail: 'Issuer deleted',
          group: 'info',
          life: 3000,
        })
      } catch (e) {
        console.log('err', e)
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Issuer not delete',
          group: 'info',
          life: 3000,
        })
      }
      this.deleteDialog = false
      this.getRequestToIssues()
    },
    clear() {
      this.tokenName = ''
      this.tokenMnemonic = ''
      this.description = ''
      this.tokenCount = ''
      this.dividends = ''
      this.discount = ''
      this.isVotingRight = false
      this.infinityDate = false
      this.stopDate = ''
      this.logicType = ''
      this.selectedCompany = ''
      this.selectedEth = ''
    },
    showCreateDialog() {
      this.editValue = null
      this.clear()
      this.createEditDialog = true
    },
    checkEditOrCreate() {
      if (this.editValue === null) this.createRequestToIssuer()
      else this.editConfirm()
    },
  },
  computed: {
    allField() {
      return (
        this.tokenName.length > 1 &&
        // this.tokenMnemonic.length > 1 &&
        this.$filters.correctMnemonic(this.tokenMnemonic) &&
        this.$filters.correctNumber(this.tokenCount) &&
        this.selectedCompany !== null &&
        this.selectedEth !== null &&
        this.logicType !== ''
      )
    },
    dialogHeader() {
      return this.editValue === null
        ? 'Создание запросов на выпуск ЦФА'
        : 'Редактирование ЦФА'
    },
    buttonText() {
      return this.editValue === null ? 'Создать заявку' : 'Редактировать'
    },
    isManager() {
      return this.$auth.user().type === 'manager'
    },
  },
}
</script>

<style scoped>
.LabelText {
  font-size: smaller;
}
</style>
